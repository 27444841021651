<template>
  <!-- <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: item.index }" v-for="(item,index) in routerList" :key="index" @click="onClick(item)">{{item.title}}</el-breadcrumb-item>
  </el-breadcrumb> -->
  <div class="flex gap-2">
    <el-tabs
      v-model="editableTabsValue"
      type="card"
      editable
      class="demo-tabs"
      @edit="handleClose"
      @tab-click="onClick"
    >
      <el-tab-pane
        v-for="item in routerList"
        :key="item.index"
        :label="item.title"
        :name="item.route"
      >
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { getSession, setSession } from "../utils/util.js";
import { useRoute } from "vue-router";
import router from "@/router";
const routerList = ref([]);
const editableTabsValue = ref("");
onMounted(() => {
  routerList.value = getSession("routerList");
  const currentRoute = useRoute();
  editableTabsValue.value = currentRoute.path;
});
watch(
  () => router.currentRoute.value,
  (newValue) => {
    routerList.value = getSession("routerList");
    editableTabsValue.value = newValue.fullPath 
  }
);
const onClick = (item) => {
  router.push({ path: item.props.name });
};
const currentRoute = useRoute();
const handleClose = (TabPaneName) => {
  if (TabPaneName !== currentRoute.path) {
    const index = routerList.value.findIndex(
      (item) => item.route === TabPaneName
    );
    if (index !== -1) {
      routerList.value.splice(index, 1);
    }
    setSession("routerList", routerList.value);
  }
};
</script>

<style scoped>
.el-tag {
  margin-right: 5px;
  cursor: pointer;
}
::v-deep .el-tabs__new-tab {
  display: none;
}
::v-deep .el-tabs--card > .el-tabs__header {
  border: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
  border: 1px solid var(--el-border-color-light);
  margin-left: 10px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  border-left: 1px solid var(--el-border-color-light);
}
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
  border-bottom-color:var(--el-border-color-light)
}
</style>
