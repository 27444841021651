export const uploadUrl = process.env.VUE_APP_UPLOAD_URL
export function getSession(key) {
   const value = window.sessionStorage.getItem(key)
  try {
     return JSON.parse(window.sessionStorage.getItem(key))
  } catch (error) {
     return value
  }
}

export function setSession(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeSession(key) {
  window.sessionStorage.removeItem(key)
}
